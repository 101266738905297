import { engageFhirApi } from "./EngageFhirApi";
import store from "@/store";

/**
 * Retrieve app config feature flags on a set cadence if the user is logged in; update/wipe flags on login/logout.
 * @remarks Current professional in state is used as a signal for login status.
 */
export class AppConfigManagementService {
  appConfigSyncFrequency;
  firstFetch = true;
  isLoggedIn = false;
  constructor() {
    this.appConfigSyncFrequency = 1000 * 60 * 5;

    store.watch(
      (state, getters) => getters["currentProfessional/email"],
      async (newValue, oldValue) => {
        this.isLoggedIn = !!newValue;
        if (newValue || oldValue) {
          // username is in: newValue.account.userName
          await this.delay(500);
          this.firstFetch = false;
          await this.getAppConfig();
        }
      }
    );
  }

  async getAppConfig() {
    if (!this.isLoggedIn) {
      store.dispatch("appConfig/updateAppConfig", null);
      return;
    }
    try {
      const appConfig = await engageFhirApi.get("/feature-flags");
      store.dispatch("appConfig/updateAppConfig", appConfig);
    } catch (error) {
      khanSolo.error(`Error fetching app config. Retaining stale configuration. ${error}`);
    }
  }

  /** Start polling for app config. */
  syncAppConfigOngoing() {
    setInterval(async () => await this.getAppConfig(), this.appConfigSyncFrequency);
  }

  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}

export const appConfigManagementService = new AppConfigManagementService();
