import Vue from "vue";
import Vuex from "vuex";
import currentProfessional from "./modules/currentProfessional";
import tasks from "./modules/tasks";
import professionalSettings from "./modules/professionalSettings";
import websocket from "./modules/websocket";
import chatProfessional from "./modules/chatProfessional";
import errorHandler from "./modules/errorHandler";
import dbugga from "./modules/dbugga";
import prt from "./modules/prt";
import diagnosisConsiderations from "./modules/diagnosisConsiderations";
import patients from "./modules/patients";
import security from "./modules/security";
import enrollment from "./modules/enrollment";
import organizations from "./modules/organizations";
import appConfig from "./modules/appConfig";
import auth from "@/utils/Auth";
import { cloneDeep, forOwn } from "lodash";

export const initialStoreModules = {
  currentProfessional,
  tasks,
  professionalSettings,
  websocket,
  chatProfessional,
  errorHandler,
  dbugga,
  patients,
  prt,
  security,
  enrollment,
  diagnosisConsiderations,
  organizations,
  appConfig
};

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    panelFlow: {
      prevPanel: "",
      currentPanel: ""
    },
    routerKey: 0 || auth.getOrgId()
  },
  modules: cloneDeep(initialStoreModules),
  mutations: {
    // reset default state modules by looping around the initialStoreModules
    resetStore(state) {
      forOwn(initialStoreModules, (value, key) => {
        state[key] = cloneDeep(value.state);
      });
    },
    setPanelFlow(state, panels) {
      state.panelFlow = panels;
    },
    setRouterKey(state, rKey) {
      state.routerKey = rKey;
    }
  },
  getters: {},
  actions: {}
});
