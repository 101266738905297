import getActiveConvos from "@/services/Api/getActiveConvos";
import AuthState from "@/utils/Auth";
import { engageFhirApi } from "../../services/EngageFhirApi";

export default {
  namespaced: true,
  state: {
    activeConvos: [],
    convoInitiatorType: "direct",
    convosCounts: {
      direct: {
        totalRecords: "<i>Loading...</i>",
        awaitingResponse: "<i>Loading...</i>"
      },
      system: "<i>Loading...</i>"
    },
    convosOrderby: "needs_response",
    convosOrderDirection: "DESC",
    convosPageNum: 1,
    convosPerPage: 20,
    currentInteractionId: undefined,
    newInteraction: false,
    totalRecords: 0,
    messages: [],
    messagesLoading: false,
    activeConvosLoading: true,
    beginningOfConvos: false,
    messagesPayload: {},
    atBeginningOfMessages: false,
    beginningOfChatTxt: {
      body: "Beginning of Chat",
      channel: "sms",
      direction: "none",
      id: null
    },
    scrollListening: false,
    convosScrollListening: false,
    currentPatient: {}
  },
  mutations: {
    activeConvos(state, payload) {
      state.activeConvos = payload;
    },
    convoInitiatorType(state, payload) {
      state.convoInitiatorType = payload;
    },
    convosCounts(state, payload) {
      state.convosCounts = payload;
    },
    convosOrderby(state, payload) {
      state.convosOrderby = payload;
    },
    convosOrderDirection(state, payload) {
      state.convosOrderDirection = payload;
    },
    convosPageNum(state, payload) {
      state.convosPageNum = payload;
    },
    currentInteractionId(state, payload) {
      state.currentInteractionId = payload;
    },
    enableConvosLoader(state) {
      state.activeConvosLoading = true;
    },
    disableConvosLoader(state) {
      state.activeConvosLoading = false;
    },
    toggleNewInteraction(state) {
      state.newInteraction = !state.newInteraction;
    },
    totalRecords(state, payload) {
      state.totalRecords = payload;
    },
    messages(state, payload) {
      state.messages = [...state.messages, ...payload];
    },
    clearMessages(state) {
      state.messages = [];
    },
    clearMessagesPayload(state) {
      state.messagesPayload = {};
    },
    messagesPayload(state, payload) {
      state.messagesPayload = payload;
    },
    toggleMessagesLoading(state, payload) {
      state.messagesLoading = payload;
    },
    initializeMessages(state) {
      state.messages = [];
      state.atBeginningOfMessages = false;
      state.scrollListening = false;
    },
    toggleScrollListening(state) {
      state.scrollListening = !state.scrollListening;
    },
    turnScrollListeningOn(state) {
      state.scrollListening = true;
    },
    turnScrollListeningOff(state) {
      state.scrollListening = false;
    },
    turnConvosScrollListeningOn(state) {
      state.convosScrollListening = true;
    },
    turnConvosScrollListeningOff(state) {
      state.convosScrollListening = false;
    },
    setCurrentPatient(state, payload) {
      state.currentPatient = payload;
    }
  },
  getters: {
    activeConvos(state) {
      return state.activeConvos;
    },
    convosCounts(state) {
      return state.convosCounts;
    },
    convoInitiatorType(state) {
      return state.convoInitiatorType;
    },
    convosOrderby(state) {
      return state.convosOrderby;
    },
    convosOrderDirection(state) {
      return state.convosOrderDirection;
    },
    convosPageNum(state) {
      return state.convosPageNum;
    },
    currentInteractionId(state) {
      return state.currentInteractionId;
    },
    newInteraction(state) {
      return state.newInteraction;
    },
    activeConvosLoading(state) {
      return state.activeConvosLoading;
    },
    messages(state) {
      return state.messages;
    },
    messagesPayload(state) {
      return state.messagesPayload;
    },
    messagesLoading(state) {
      return state.messagesLoading;
    },
    atBeginningOfMessages(state) {
      return state.atBeginningOfMessages;
    },
    beginningOfConvos(state) {
      return state.beginningOfConvos;
    },
    scrollListening(state) {
      return state.scrollListening;
    },
    convosScrollListening(state) {
      return state.convosScrollListening;
    }
  },
  actions: {
    async getActiveSystemConvosCount({ commit }) {
      const systemConvosResult = await getActiveConvos(
        "system",
        1,
        1,
        "active",
        undefined,
        undefined,
        this.state.chatProfessional.convosOrderby,
        this.state.chatProfessional.convosOrderDirection
      );

      const convosCounts = this.state.chatProfessional.convosCounts;
      convosCounts.system = systemConvosResult.totalRecords;
      commit("convosCounts", convosCounts);
    },

    async getPartialActiveConvos({ commit, state }) {
      const activeConvosResult = await getActiveConvos(
        this.state.chatProfessional.convoInitiatorType === "closed"
          ? undefined
          : this.state.chatProfessional.convoInitiatorType,
        this.state.chatProfessional.convosPageNum,
        this.state.chatProfessional.convosPerPage,
        this.state.chatProfessional.convoInitiatorType === "closed" ? "closed" : "active",
        undefined,
        undefined,
        this.state.chatProfessional.convosOrderby,
        this.state.chatProfessional.convosOrderDirection
      );

      if (this.state.chatProfessional.convoInitiatorType != "closed") {
        const convosCounts = this.state.chatProfessional.convosCounts;

        commit("convosCounts", await getCounts(convosCounts));
      }

      const comboConvos = [...state.activeConvos, ...activeConvosResult.results];

      if (comboConvos.length == state.totalRecords) {
        state.beginningOfConvos = true;
      }
      commit("activeConvos", comboConvos);
      commit("disableConvosLoader");
    },

    async updateCurrentActiveConvos({ commit, state }) {
      let perPage;
      if (state.newInteraction) {
        perPage = state.activeConvos.length + 1;
        commit("toggleNewInteraction");
      } else {
        perPage = state.activeConvos.length;
      }

      const activeConvosResult = await getActiveConvos(
        this.state.chatProfessional.convoInitiatorType === "closed"
          ? undefined
          : this.state.chatProfessional.convoInitiatorType,
        1,
        perPage,
        this.state.chatProfessional.convoInitiatorType === "closed" ? "closed" : "active",
        undefined,
        undefined,
        this.state.chatProfessional.convosOrderby,
        this.state.chatProfessional.convosOrderDirection
      );
      if (
        activeConvosResult &&
        activeConvosResult.results &&
        activeConvosResult.results.length == state.totalRecords
      ) {
        state.beginningOfConvos = true;
      }

      if (
        this.state.chatProfessional.convoInitiatorType != "closed" &&
        this.state.chatProfessional.convoInitiatorType != "system" &&
        activeConvosResult
      ) {
        const convosCounts = this.state.chatProfessional.convosCounts;

        commit("convosCounts", await getCounts(convosCounts));
      }

      commit("activeConvos", activeConvosResult.results);
      commit("disableConvosLoader");
    },
    async setConvoInitiatorType({ commit, state }, payload) {
      commit("disableConvosLoader");
      state.beginningOfConvos = false;
      state.convosPageNum = 1;
      commit("convoInitiatorType", payload);
    },
    async convoInitiatorType({ commit, state }, payload) {
      commit("turnConvosScrollListeningOff");
      state.activeConvos = [];
      state.beginningOfConvos = false;
      state.convosPageNum = 1;
      commit("convoInitiatorType", payload);
    },
    async convosOrderby({ commit, state }, payload) {
      commit("disableConvosLoader");
      state.activeConvos = [];
      state.beginningOfConvos = false;
      state.convosPageNum = 1;
      commit("convosOrderby", payload);
    },
    async convosOrderDirection({ commit, state }, payload) {
      commit("disableConvosLoader");
      state.activeConvos = [];
      state.beginningOfConvos = false;
      state.convosPageNum = 1;
      commit("convosOrderDirection", payload === "needs_response" ? "DESC" : payload);
    },
    async convosPageNum({ commit }, payload) {
      commit("convosPageNum", payload);
    },
    async currentInteractionId({ commit }, payload) {
      commit("currentInteractionId", payload.id);
    },
    async updateMessages({ commit, state }, payload) {
      const { interactionId, patientId, pageNumber, messagesPerPage } = payload;

      const claims = await AuthState.claims();
      const skip = pageNumber ? (pageNumber - 1) * messagesPerPage : 0;

      const fhirPayload = {
        filterByOrgIds: AuthState.getOrgId() ? AuthState.getOrgId() : claims.orgIds, // add to webapp api
        searchValue: interactionId ? interactionId : undefined,
        patientId: patientId ? patientId : undefined,
        skip,
        take: messagesPerPage ? messagesPerPage : 20
      };
      const fhirMessageResults = await engageFhirApi.get("Communication", fhirPayload);

      commit("messagesPayload", {
        messages: fhirMessageResults.messages,
        initiator: fhirMessageResults.initiator,
        urgency: fhirMessageResults.urgency,
        status: fhirMessageResults.status,
        programId: fhirMessageResults.programId,
        isConvoLocked: fhirMessageResults.isConvoLocked,
        subject: fhirMessageResults.subject
      });

      if (
        fhirMessageResults.messages &&
        fhirMessageResults.messages.length < messagesPerPage &&
        !state.atBeginningOfTime
      ) {
        state.atBeginningOfMessages = true;
        fhirMessageResults.messages && fhirMessageResults.messages.push(state.beginningOfChatTxt);
        commit("messages", fhirMessageResults.messages);
      } else if (fhirMessageResults.messages && fhirMessageResults.messages.length > 0) {
        commit("messages", fhirMessageResults.messages);
      }
    }
  }
};

async function getCounts(convosCounts) {
  const payload = {
    filterByOrgIds: AuthState.getOrgId() ? AuthState.getOrgId() : this.claims.orgIds,
    searchValue: undefined,
    status: "active",
    skip: 0,
    take: 1,
    sortKey: "created_at",
    needsResponse: false,
    sortDirection: "DESC"
  };

  const result = await engageFhirApi.get("CommunicationRequest", payload);
  convosCounts.direct.totalRecords = result.totalRecords;

  payload.needsResponse = true;

  const needsResponseResult = await engageFhirApi.get("CommunicationRequest", payload);
  convosCounts.direct.awaitingResponse = needsResponseResult.totalRecords;

  return convosCounts;
}
