import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Ionic from "@ionic/vue";
import "@ionic/core/css/ionic.bundle.css";
import { LicenseManager } from "@ag-grid-enterprise/core";
import VueGoodTablePlugin from "vue-good-table";
import vueMoment from "vue-moment";
import moment from "moment-timezone";
import AuthState from "@/utils/Auth";
import languagePack from "@/assets/languagePack";
import IdleVue from "idle-vue";
import VTooltip from "v-tooltip";
import TextareaAutosize from "vue-textarea-autosize";
import VCalendar from "v-calendar";
import vSelect from "vue-select";
import VueChatScroll from "vue-chat-scroll";
import VueInputMask from "vue-inputmask";
import Logger from "@/utils/Logger";
import { formatDisplayName } from "@/utils/formatter";
import Dbugga from "@/components/Global/Dbugga";
// eslint-disable-next-line no-unused-vars
import config from "@/configuration";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import { isMobile, mobilize } from "./utils/mobilize";
import { permissionsRemark } from "./utils/permissions";
import Security from "@/plugins/Security";
import CopyPlugin from "@/plugins/Copy";
import El from "@/components/utility/El";
import PrimeButton from "@/components/Global/PrimeButton";
import PrimeCheckbox from "@/components/Global/PrimeCheckbox";
import Vue2Editor from "vue2-editor";
import "@/mixins/outsideClick";
import { applyPolyfills, defineCustomElements } from "@engage/common-web-components/loader";
import { appConfigManagementService } from "./services/AppConfigManagement";

applyPolyfills().then(() => {
  defineCustomElements();
});

import VueDompurifyHTML from "vue-dompurify-html";

// Khaaaaaaaaaan!
window.khanSolo = Logger;
// ag-grid license
LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-054074}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Lumeris_Solutions_Company,_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{MyPCP}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{MyPCP}_need_to_be_licensed___{MyPCP}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{24_March_2025}____[v3]_[01]_MTc0Mjc3NDQwMDAwMA==c2dba0ae19e86243218e905721827aaf"
);
//SESSION TIMOUT EVENT EMITTER
//TODO: this is just a duplicate instance
// of our EventBus. At some future date lets
// ditch this and keep EventBus
const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 1200000
});
// END SESSION TIMEOUT EMITTER

// globals
Vue.prototype.$authState = AuthState;
Vue.prototype.$mypcpRouter = router;
Vue.prototype.$languagePack = languagePack;

Vue.use(VueGoodTablePlugin);
Vue.use(Ionic, {
  rippleEffect: false
  // mode: 'ios',
});
Vue.use(vueMoment, { moment });
Vue.use(VTooltip);
Vue.use(TextareaAutosize);
Vue.use(VCalendar);
Vue.use(vSelect);
Vue.use(VueChatScroll);
Vue.use(VueInputMask.default);
Vue.use(Chartkick.use(Chart));
Vue.use(Security);
Vue.use(CopyPlugin);
Vue.use(Vue2Editor);
Vue.use(VueDompurifyHTML);

// Global Filters
Vue.filter("formatDisplayName", formatDisplayName);

// Global Components
Vue.component("Dbugga", Dbugga);
Vue.component("El", El);
Vue.component("PrimeButton", PrimeButton);
Vue.component("PrimeCheckbox", PrimeCheckbox);

Vue.config.productionTip = false;
if (document.config.environment != "prod") {
  Vue.config.devtools = true;
}
Vue.prototype.$isMobile = isMobile();
Vue.prototype.$mobilize = (prevPanel, currentPanel, currentCssClass) => {
  mobilize(prevPanel, currentPanel, currentCssClass);
};

Vue.prototype.$orgMissingPermissions = feature => {
  return permissionsRemark(feature);
};

// Initialize feature flag polling
appConfigManagementService.syncAppConfigOngoing();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
