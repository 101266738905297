import AuthState from "@/utils/Auth";
import axios from "axios";
// import store from "@/store";
import router from "@/router";
import { toastController } from "@ionic/core";

export const send = async args => {
  const { path, method = "GET", payload, headers = {}, authToken, apiKey, responseType } = args;
  // add auth header if token is passed.
  if (authToken) {
    const token = await AuthState.accessToken();

    const orgId = AuthState.getOrgId();
    headers.Authorization = "Bearer " + token;
    headers["X-API-Key"] = apiKey ? apiKey : "";
    headers["x-org-id"] = orgId ? orgId : "";
  }
  return new Promise(async (resolve, reject) => {
    const httpBits = {
      method,
      headers: { ...headers },
      url: path,
      data: payload,
      responseType: responseType
    };

    try {
      const response = await axios(httpBits);
      resolve(response);
    } catch (err) {
      if (!err.response) {
        reject(err);
      }
      const errNumbers = [400, 401, 403];
      const { status } = err.response.data;
      if (errNumbers.includes(status)) {
        toastController
          .create({
            header: "There was a problem",
            message: "Please login again",
            duration: 10000,
            position: "top"
          })
          .then(m => m.present());

        router.push({
          path: "/logout"
        });
      } else {
        reject(err);
      }
    }
  });
};
